.landing {
    
    .cover {
        // background-image: url(../images/cover.webp) no-repeat center center;
        height: 100%;
        
        // @media only screen and ($desktop) {
        //     height: 700px;
        // }
    }
}