.popular-trends {
  .top-content {
    text-align: center;
    margin: 20px 0;
  }

  .trends-grid {
    display: grid;
    justify-content: center;
    padding: 20px;
    grid-gap: 30px;

    @media ($tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ($desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .card {
    text-align: center;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      box-shadow: $box-shadow;
    }

    h3 {
      border: 1px solid lighten($dark-color, 80%);
      margin-bottom: 30px;
      padding: 20px;
      box-shadow: $box-shadow;
    }
  }
}
