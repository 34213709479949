footer {
  background-color: $dark-color;
  background-size: cover;
  color: $light-color;

  .top-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: flex-start;
    padding: 30px;
    font-family: $primary-font-stack;

    @media ($tablet) {
      justify-content: space-between;
      padding: 50px 0;
    }

    h3 {
      margin-bottom: 20px;
      font-size: 1.2rem;
    }

    .hours-table {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      div {
        padding-bottom: 5px;
        font-size: 1rem;
      }
    }

    .social {
      @media only screen and ($desktopXL) {
        a {
          display: flex;
          font-size: 30px;

          .icon {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
